import React from 'react';
import Quote from '../images/quote.png'; 
import Invoice from '../images/invoice.png'; 
import CRM from '../images/crm.png'; 
import { useNavigate } from 'react-router-dom';


const KeyFeaturesSection = () => {
  const navigate = useNavigate();
  const features = [
    { imageSrc: CRM, title: "CRM", subtitle: "ORGANISE YOUR BUSINESS" },
    { imageSrc: Quote, title: "Quote", subtitle: "SAVE TIME" },
    { imageSrc: Invoice, title: "Invoice", subtitle: "EASY CIS REVERSAL" },
  ];

  const registerInterest = () => {
    navigate('/early-signup');
  };

  return (
    <section className="features-section">
      <h1 className="features-title">A simple ERP for construction professionals</h1>
      
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-image-container">
              <img 
                src={feature.imageSrc} 
                alt={feature.title}
                className="feature-image"
              />
              <div className="feature-overlay">
                <span className="feature-title">{feature.title}</span>
              </div>
            </div>
            <div className="feature-subtitle">{feature.subtitle}</div>
          </div>
        ))}
      </div>

      <button 
        onClick={() => registerInterest()}
        className="signup-button"
      >
        Sign up
      </button>
    </section>
  );
};

export default KeyFeaturesSection;