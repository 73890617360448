import React from 'react';
import { useNavigate } from 'react-router-dom';

const TestimonialsSection = () => {
  const navigate = useNavigate();
  
  const registerInterest = () => {
    navigate('/early-signup');
  };

  const testimonialContent = [
    { text: '"Before SiteSteer, creating accurate quotes felt like pulling teeth. It was time-consuming and prone to errors. Now, I can quickly generate professional-looking quotes with just a few clicks. SiteSteer has streamlined our quoting process, allowing us to win more bids and improve our overall profitability."',
      company: ""},
    { text: `"Since implementing SiteSteer, our invoicing process has become a breeze. We're getting paid faster, and our cash flow has significantly improved. Highly recommend!"`,
    company: ""},
    { text: `"Before SiteSteer, navigating CIS Reverse Charge was a nightmare. It was time-consuming and confusing to ensure we were applying it correctly on every invoice. Now, with SiteSteer, it's a breeze. The system automatically calculates and applies CIS Reverse Charge with a single click. This has saved us countless hours and given us peace of mind knowing we're always compliant."`,
    company: ""},
  ]

  return (
    <section className="testimonials-section">
      <h2 className="testimonials-title">Testimonials</h2>
      
      <button 
        onClick={() => registerInterest()}
        className="signup-button"
      >
        Sign up
      </button>

      <div className="testimonials-grid">
        {testimonialContent.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-content">
              <p className="testimonial-text">{testimonial.text}</p>
              <p className="testimonial-company">{testimonial.company}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsSection;