import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import HeroSection from './Homepage/HeroSection';
import KeyFeaturesSection from './Homepage/KeyFeaturesSection';
import CisReversal from './Homepage/CisReversal';
import Testimonials from './Homepage/Testimonials';
import ContactUs from './Homepage/ContactUs';
import Pricing from './Homepage/Pricing';
import Footer from './Homepage/Footer';

const Root = () => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <div className="root-container">
        <div className="loggedin-content">
          <h1>Welcome to SiteSteer</h1>
          <p>You are logged in. Please use the navigation menu to access different parts of the application.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="root-container">
      <HeroSection />
      <KeyFeaturesSection />
      <CisReversal />
      <Testimonials />
      <ContactUs />
      <Pricing />
      <Footer />
    </div>
  );
};

export default Root;