// Prospects.jsx
import React, { useState, useContext, useEffect, useMemo, useRef, useCallback} from "react";
import {
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext"; // Context for user information
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenuFilterItem,
  GridColumnMenuSortItem,
  getGridStringOperators,
} from "@mui/x-data-grid"; // MUI DataGrid components
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Hooks for navigation and accessing location
import { useAuth0 } from "@auth0/auth0-react"; // Auth0 for authentication
import ArchiveIcon from "@mui/icons-material/Archive"; // Icon for archive action
import EditIcon from "@mui/icons-material/Edit"; // Icon for edit action
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete action
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';  // Icon for pushing action
import {
  fetchProspectData,
  fetchCrmCompanies,
  updateProspectData,
} from "../components/Backend Routes/ProspectFetch"; // Backend API functions
import { deleteEntry } from "../components/Backend Routes/GeneralDelete"; // Backend API functions
import { archiveEntry } from "../components/Backend Routes/GeneralArchive"; // Backend API functions
import { useArchivedFilter } from '../components/General Components/hooks/useArchivedFilter';
import { useCompanyLookup } from '../components/General Components/hooks/useCompanyLookup';
import { useURLParameters } from '../components/General Components/hooks/useURLParameters';
import { useArchiveOperation } from '../components/General Components/hooks/useArchiveOperation';
import { useDeleteOperation } from '../components/General Components/hooks/useDeleteOperation';
import DeleteConfirmationDialog from '../components/General Components/components/DeleteConfirmationDialog';
import ArchiveConfirmationDialog from '../components/General Components/components/ArchiveConfirmationDialog';
import { ArchiveToggle } from '../components/General Components/components/ArchiveToggle';
import { FilterBubbles } from '../components/General Components/components/FilterBubbles';
import ProspectsDialog from '../components/Prospects Components/ProspectsDialog';

const Prospects = () => {
  // Initialize navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location:', location.search);

  // Ref flag to indicate programmatic filter updates
  const initialFilterSet = useRef(false);

  // Extract user information from Auth0
  const { user } = useAuth0();

  // Extract premium user information from context
  const { isPremiumUser, premiumKey } = useContext(UserContext);

  // State variables for handling automatic opening of Add Popup
  const [shouldOpenAddPopup, setShouldOpenAddPopup] = useState(false);

  // State variables for toggling archiving
  const [showArchived, setShowArchived] = useState(false);

  // State for storing prospect and CRM companies data
  const [prospectData, setProspectData] = useState([]);
  const [crmCompanies, setCrmCompanies] = useState([]);

  // State variables for loading and error handling
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [initialCompanyId, setInitialCompanyId] = useState(null);
  const [initialProjectId, setInitialProjectId] = useState(null);

  // state for submit loading
  const [isSubmitting, setIsSubmitting] = useState(false);

  // State variables for add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [editedItem, setEditedItem] = useState({
    projectName: "",
    site_address: "",
    companyId: "",
    projectId: "", // Assuming projectId is a unique identifier
    status: "",
  });

  // State for managing the filter model in DataGrid
  const [filterModel, setFilterModel] = useState({ items: [] });

  // Snackbar state variable set and include severity
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" // Can be "success", "error", "warning", "info"
  });

  // Use custom hooks
  const filteredData = useArchivedFilter(prospectData, showArchived);
  const companyLookup = useCompanyLookup(prospectData);
  
  /**
   * Fetching prospect and CRM companies data from the backend API.
   * Ensures that each prospect has a unique 'id' required by DataGrid.
   */
  const loadData = useCallback(async () => {
    try {
      const prospects = await fetchProspectData(premiumKey);
      const companies = await fetchCrmCompanies(premiumKey);
      
      // Initialize a Set to track unique projectIds
      const idSet = new Set();
      
      // Map each prospect to include a unique 'id' based on 'projectId'
      const mappedProspects = prospects.map((prospect) => {
        if (!prospect.projectId) {
          console.warn('Prospect missing projectId:', prospect);
          // Assign a temporary unique id if 'projectId' is missing
          return { ...prospect, id: `missing-id-${Math.random()}` };
        }
        if (idSet.has(prospect.projectId)) {
          console.warn('Duplicate projectId found:', prospect.projectId);
          // Assign a unique id by appending a random number if 'projectId' is duplicated
          return { ...prospect, id: `duplicate-id-${prospect.projectId}-${Math.random()}` };
        }
        idSet.add(prospect.projectId);
        return { ...prospect, id: prospect.projectId };
      });
      
      setProspectData(mappedProspects);
      setCrmCompanies(companies);
      setLoading(false);
      setDataLoaded(true);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [premiumKey]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // URL parameters handling
  const { resetFilterFlag } = useURLParameters({
    onSetInitialIds: ({ companyId, projectId }) => {
      setInitialCompanyId(companyId);
      setInitialProjectId(projectId);
    },
    onSetFilter: (filterModel) => {
      setFilterModel(filterModel);
      initialFilterSet.current = true;
    },
    companyLookup
  });

    /**
   * If data is loaded and an additional initialCompanyId is set from
   * the URL search paramters it then sets companyId of EditedItem for the popup to prepopulate
   */
  useEffect(() => {
    if (dataLoaded && initialCompanyId) {
      const company = crmCompanies.find(c => c.companyId === initialCompanyId);
      if (company) {
        setEditedItem({
          projectName: "",
          site_address: "",
          companyId: company.companyId,
          projectId: "",
          status: "",
        });
        setOpenAddEditPopup(true);
      }
      setInitialCompanyId(null); // Reset to prevent reopening
    }
  }, [dataLoaded, initialCompanyId, crmCompanies]);

  // Opens AddEdit
  useEffect(() => {
    if (shouldOpenAddPopup) {
      handleAddEdit();
      setShouldOpenAddPopup(false);
    }
  }, [shouldOpenAddPopup]);


  /**
   * Handle opening the add/edit popup.
   * If an 'id' is provided, populate the form with existing data for editing.
   * Otherwise, reset the form for adding a new prospect.
   * @param {string|null} id - The unique identifier of the prospect to edit, or null for adding.
   */
  const handleAddEdit = (id = null) => {
    if (id !== null) {
      const itemToEdit = prospectData.find((item) => item.id === id);
      console.log("Prospects - Editing item:", itemToEdit);
      setEditedItem({ ...itemToEdit }); // Populate form with existing data
    } else {
      // Reset form fields for adding a new prospect
      setEditedItem({
        projectName: "",
        address: "",
        companyId: "",
        projectId: "",
        status: "",
      });
      console.log("Prospects - Adding new prospect.");
    }
    setOpenAddEditPopup(true); // Open the add/edit dialog
  };

  /**
   * Handle initiating the deletion of a prospect.
   * Opens a confirmation dialog.
   */
  const {
    handleDelete,
    openConfirmationDialog: openDeleteDialog,
    confirmDelete,
    setConfirmDelete,
    handleConfirmDelete,
    handleCloseDialog: handleCloseDeleteDialog,
    isDeleting,
  } = useDeleteOperation({
    itemName: 'prospect',
    onDelete: async (id) => {
      // Logic to delete the item
      try {
        // Remove the item from local state using prospectData
        const updatedData = prospectData.filter((item) => item.id !== id);

        // Optionally, send a request to your backend to delete the item
        // await deleteProspect(premiumKey, id);
        await deleteEntry(premiumKey, id, 'prospect', 'projectId');

        setProspectData(updatedData); // Use setProspectData here

        // Show success message
        setSnackbar({
          open: true,
          message: 'Prospect successfully deleted',
          severity: 'success',
        });
      } catch (error) {
        // Show error message
        setSnackbar({
          open: true,
          message: `Failed to delete prospect: ${error.message}`,
          severity: 'error',
        });
      }
    },
  });

 /**
   * Handle initiating the archiving of a prospect.
   * Opens an archive confirmation dialog or performs archiving logic.
   * Sends an Archive request to the backend API and updates local state upon success.
   */
  const {
    handleArchive,
    openConfirmationDialog,
    confirmArchive,
    setConfirmArchive,
    handleConfirmArchive,
    handleCloseDialog,
    isArchiving,
  } = useArchiveOperation({
    itemName: 'prospect',
    onArchive: async (id) => {
      // Logic to archive the item
      try {
        // send a request to your backend to archive the item
        await archiveEntry(premiumKey, id, 'prospect', 'projectId');

        // Update local state to reflect the change
        setProspectData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, status: "Archived" } : item
          )
        );

        // Show success message
        setSnackbar({
          open: true,
          message: 'Prospect successfully archived',
          severity: 'success',
        });
      } catch (error) {
        // Show error message
        setSnackbar({
          open: true,
          message: `Failed to archive prospect: ${error.message}`,
          severity: 'error',
        });
      }
    },
  });

   /**
   * Handle initiating the creation of a quote.
   */
  const handleCreateQuote = (id) => {
    console.log(`Prospects - Initiating prospect creation for id: ${id}`);
    const prospect = prospectData.find(item => item.id === id);
    if (prospect) {
      navigate(`/quotes?action=add&companyId=${prospect.companyId}&projectId=${encodeURIComponent(prospect.projectId)}`);
    }
  };

  /**
   * Handle changes in the add/edit form fields.
   * Updates the 'editedItem' state accordingly.
   * @param {string} field - The form field being updated.
   * @param {string} value - The new value of the form field.
   */
  const handleChange = (field, value) => {
    setEditedItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));
    console.log(`Prospects - Updated field '${field}' to '${value}'.`);
  };

  /**
   * Handle the submission of the add/edit form.
   * Sends a POST or PUT request to the backend API based on whether an 'id' exists.
   * Refreshes the prospect data upon successful submission.
   */
  const handleSubmit = async () => {
    if (isSubmitting) return; // Prevent double submission
    
    setIsSubmitting(true);
    try {
      const selectedCompany = crmCompanies.find(company => company.companyId === editedItem.companyId);

      let newProspect = {
        ...editedItem,
        companyName: selectedCompany ? selectedCompany.customer_name : 'Unknown Company',
        id: editedItem.projectId || `new-${Date.now()}`, // Use projectId as id
      };

      let updatedData;
      if (editedItem.id !== undefined) {
        updatedData = prospectData.map(item => 
          item.id === editedItem.id ? newProspect : item
        );
      } else {
        updatedData = [...prospectData, newProspect];
      }

      // Send update to backend
      const response = await updateProspectData(premiumKey, updatedData);
      
      // Transform the response data to include id field
      if (response && response.items) {
        const transformedData = response.items.map(item => ({
          ...item,
          id: item.projectId // Use projectId as id
        }));
        setProspectData(transformedData);
      } else {
        setProspectData(updatedData);
      }

      setOpenAddEditPopup(false);
      setSnackbar({
        open: true,
        message: editedItem.id !== undefined 
          ? "Prospect successfully edited"
          : "New prospect successfully added to Prospects",
        severity: "success"
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update prospect entry",
        severity: "error"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Handle closing the Snackbar message.
   */
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbar({ ...snackbar, open: false });
    };


  /**
   * Custom Column Menu for DataGrid.
   * Restricts the column menu to only sort and filter options.
   * @param {object} props - Properties passed to the custom column menu.
   */
  const CustomColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
      <div>
        <GridColumnMenuSortItem onClick={hideMenu} column={currentColumn} />
        <GridColumnMenuFilterItem onClick={hideMenu} column={currentColumn} />
      </div>
    );
  };

  /**
   * Define the columns for the DataGrid, including field names, header names, flex properties, and filter operators.
   * The 'actions' column includes edit, delete, and archive actions for each row.
   */
  const columns = useMemo(
    () => [
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "projectName",
        headerName: "Project Name",
        flex: 1.5,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "site_address",
        headerName: "Project Address",
        flex: 2,
        minWidth: 300,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      // {
      //   field: "most_recent",
      //   headerName: "Most Recent Activity",
      //   flex: 1,
      //   minWidth: 150,
      //   filterOperators: getGridStringOperators().filter((op) =>
      //     ["contains", "equals"].includes(op.value)
      //   ),
      // },
      {
        field: "actions", // Special column for action buttons
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <Tooltip title="Edit Prospect">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleAddEdit(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Delete Prospect">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Archive Prospect">
            <GridActionsCellItem
              icon={<ArchiveIcon />}
              label="Archive"
              onClick={() => handleArchive(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Create Quote">
          <GridActionsCellItem
            icon={<ArrowCircleRightIcon />}
            label="Archive"
            onClick={() => handleCreateQuote(id)}
            color="inherit"
          />
          </Tooltip>,
          ];
        },
      },
    ],
    [companyLookup, handleAddEdit, handleDelete, handleArchive, handleCreateQuote] // Dependencies for useMemo
  );

  /**
   * Create a mapping from field names to header names for display in filter bubbles.
   * Utilizes React's useMemo for performance optimization.
   */
  const fieldToHeaderName = useMemo(() => {
    const mapping = {};
    columns.forEach((column) => {
      mapping[column.field] = column.headerName;
    });
    return mapping;
  }, [columns]);

  /**
   * Remove a specific filter based on its index in the filter model.
   * Updates the 'filterModel' state to exclude the removed filter.
   * @param {number} filterIndex - The index of the filter to remove.
   */
  const removeFilter = (filterIndex) => {
    console.log(`Prospects - Removing filter at index: ${filterIndex}`);
    const newItems = [...filterModel.items];
    newItems.splice(filterIndex, 1); // Remove the filter at the specified index
    setFilterModel({ ...filterModel, items: newItems }); // Update the filter model state
  };

  /**
   * Handle changes to the filter model from the DataGrid.
   * Updates the local 'filterModel' state to synchronize with filter bubbles.
   * @param {object} model - The new filter model.
   */
  const handleFilterModelChange = (newModel) => {
    console.log("Prospects - Filter Model Changed:", newModel);
    // Only update if the new model is different from the current one
    setFilterModel((prevModel) => {
      if (JSON.stringify(prevModel) !== JSON.stringify(newModel)) {
        return newModel;
      }
      return prevModel;
    });
  };

      /**
   * Handle double-click event on a DataGrid row.
   * Navigates to the Prospects page and passes the selected company's ID.
   * @param {object} params - Parameters containing row information.
   */
  const handleRowDoubleClick = (params) => {
    console.log('params double click', params)
    const companyName = params.row.companyName;
    // Navigate to Prospects page with companyName as a query parameter
    navigate(`/quotes?companyName=${companyName}`);
  };

  /**
   * Render the Prospect Details page, including the DataGrid and dialogs for add/edit/delete operations.
   */
  return (
    <div className="page-container">
      {/* Page Title and Archive Toggle */}
      <ArchiveToggle 
        showArchived={showArchived}
        onToggle={setShowArchived}
        title="Prospect Details"
      />

      <hr style={{
        borderTop: "2px solid black",
        borderBottom: "2px solid black",
      }} />

      <FilterBubbles
        filterModel={filterModel}
        fieldToHeaderName={fieldToHeaderName}
        companyLookup={companyLookup}
        onRemoveFilter={removeFilter}
      />

      {/* Add the Delete & Archive ConfirmationDialog component */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        isDeleting={isDeleting}
        itemName="prospect"
      />

      {/* Render the ArchiveConfirmationDialog */}
      <ArchiveConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmArchive}
        confirmArchive={confirmArchive}
        setConfirmArchive={setConfirmArchive}
        isArchiving={isArchiving}
        itemName="prospect"
      />

      <div className="crm-details-container">
        {loading ? (
          // Show a loading spinner while data is being fetched
          <CircularProgress />
        ) : error ? (
          // Display an error alert if there's an error fetching data
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Button to Add a New Prospect */}
            <div style={{ marginBottom: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddEdit()}
                className="standard-button"
                style={{ marginRight: "15px" }}
              >
                Add Prospect
              </Button>
            </div>

            {/* DataGrid Displaying Prospect Data */}
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData} // Use filtered data here instead of prospectData
                columns={columns} // Columns configuration
                pageSize={10} // Number of rows per page
                rowsPerPageOptions={[5, 10, 20]} // Options for rows per page
                disableSelectionOnClick // Disable row selection on click
                onRowDoubleClick={handleRowDoubleClick} // Handle row double-click
                filterModel={filterModel} // Controlled filter model
                onFilterModelChange={handleFilterModelChange} // Handle filter model changes
                components={{
                  // Use custom column menu to limit options
                  ColumnMenu: CustomColumnMenu,
                }}
                sx={{
                  // Styling for the DataGrid
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#DDD6CC",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "black",
                  },
                  "& .MuiDataGrid-menuIcon": {
                    color: "black",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    color: "black",
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Dialog for Adding/Editing a Prospect */}
      <ProspectsDialog
        open={openAddEditPopup}
        onClose={() => setOpenAddEditPopup(false)}
        onSubmit={handleSubmit}
        editedItem={editedItem}
        onFieldChange={handleChange}
        crmCompanies={crmCompanies}
        isSubmitting={isSubmitting}
      />

      {/* Snackbar for Displaying Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Prospects;
