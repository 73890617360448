import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../images/logo_nobacgkrund_withname.png";


const sections = ['Features', 'Blog'];
const homepage_links = ['/features', '/blog'];
// const sections = ['Features', 'Blog', 'Meet the team', 'Contact Us'];
// const homepage_links = ['/features', '/blog', '/team', '/contact-us'];
// const homepage_links = ['/features', 'https://blog.sitesteer.ai', '/team', '/contact-us'];

// const pages = ['CRM', 'Prospects', 'Quotes', 'Projects', 'Invoice', 'Audit Dashboard'];
// const links = ['/crm', '/prospects', '/quotes', '/live-projects', '/invoices', '/dashboard'];
const pages = ['CRM', 'Prospects', 'Quotes', 'Invoice'];
const links = ['/crm', '/prospects', '/quotes', '/invoices'];
const settings = ['Company Profile', 'Logout'];

function ResponsiveAppBar() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isRootPage = location.pathname === '/';

  // function that checks whether a link is external or internal
  const handleNavigation = (link) => {
    if (link.startsWith('https')) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      navigate(link);
    }
    handleCloseNavMenu();
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleProfileClick = () => {
    navigate('/profile');
    handleCloseUserMenu();
  };

  const registerInterest = () => {
    navigate('/early-signup');
  };

  const Back2Home = () => {
    navigate('/');
  };

  const getInitials = (name) => {
    const names = name.split(' ');
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.join('');
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#F4C50B', height: '60px'}}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img onClick={Back2Home} src={logo} alt="SiteSteer Logo!" className="app-logo" style={{ width: '90px', height: '90px', cursor: 'pointer' }} />
          </Box>
          
          {isAuthenticated ? (
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1, alignItems: 'center', height: '100%', pl: '30px', pr: '80px' }}>
              {pages.map((page, index) => (
                <React.Fragment key={page}>
                  <Button
                    onClick={() => navigate(links[index])}
                    sx={{
                      color: 'white',
                      backgroundColor: location.pathname === links[index] ? '#8784d2' : 'black',
                      borderRadius: '20px',
                      padding: '6px 16px',
                      margin: '0 4px',
                      minWidth: '250px',
                      height: '36px',
                      '&:hover': {
                        backgroundColor: location.pathname === links[index] ? '#E6E6FA' : '#333',
                      },
                    }}
                  >
                    {page}
                  </Button>
                  {index < pages.length - 1 && (
                    <ArrowForwardIosIcon sx={{ color: 'white', margin: '0 4px', fontSize: '1.5rem' }} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', pl: '30px' }}>
              {!isAuthenticated && sections.map((section, index) => (
                <Button
                  key={section}
                  component={Link}
                  to={homepage_links[index]}
                  onClick={() => handleNavigation(homepage_links[index])}
                  sx={{ 
                    color: 'black', 
                    fontWeight: 'bold',
                    backgroundColor: location.pathname === homepage_links[index] ? '#928E8E' : 'transparent',
                    '&:hover': {
                      backgroundColor: '#333',
                      color: 'white'
                    }
                  }}
                  className="nav-link-section"
                >
                  {section}
                </Button>
              ))}
            </Box>
          )}
          
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {isAuthenticated
                ? pages.map((page, index) => (
                    <MenuItem key={page} onClick={() => { navigate(links[index]); handleCloseNavMenu(); }}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))
                : sections.map((section, index) => (
                  <MenuItem 
                  key={section} 
                  onClick={() => handleNavigation(homepage_links[index])}
                  >
                    <Typography textAlign="center">{section}</Typography>
                  </MenuItem>
                  ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex' }}>
            {!isAuthenticated ? (
              <>
                <Button
                  onClick={() => loginWithRedirect()}
                  sx={{ color: 'black', fontWeight: 'bold' }}
                  className="nav-link-section"
                >
                  Login
                </Button>
                <Button
                  onClick={() => registerInterest()}
                  sx={{ my: 2, color: "white", display: "block" }}
                  className="nav-link-bubble"
                >
                  Try for Free
                </Button>
              </>
            ) : (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar className="Avatar">{user?.name ? getInitials(user.name) : ''}</Avatar>
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem 
                  key={setting} 
                  onClick={setting === 'Company Profile' ? handleProfileClick : setting === 'Logout' ? handleLogout : handleCloseUserMenu}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;



