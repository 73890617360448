// EarlySignon.jsx
import React, { useState, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Snackbar, IconButton, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText 
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TextInput from '../components/Form Components/TextInput';
import CloseIcon from '@mui/icons-material/Close';

const EarlySignon = () => {
  const { control, handleSubmit, watch, formState: { errors } } = useForm();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [signupError, setSignupError] = useState('');
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const privacyNoticeAccepted = watch('privacyNoticeAccepted', false);
  const url = `${process.env.REACT_APP_API_BASE_URL}/users/early-signon/`;

  const headingStyle = {
    textAlign: 'center',
    margin: 0,
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const handleCloseError = () => {
    setSignupError('');
  };

  // Add a state to track password validation status
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    capital: false,
    number: false,
    symbol: false
  });

  // Helper function to check password requirements
  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      capital: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
  };

  const password = watch('password', '');
  
  // Update password requirements whenever password changes
  useEffect(() => {
    setPasswordRequirements(validatePassword(password));
  }, [password]);

  const passwordValidation = {
    required: 'Password is required',
    validate: {
      length: value => value.length >= 8 || 'Password must be at least 8 characters',
      capital: value => /[A-Z]/.test(value) || 'Password must contain at least one capital letter',
      number: value => /[0-9]/.test(value) || 'Password must contain at least one number',
      symbol: value => /[!@#$%^&*(),.?":{}|<>]/.test(value) || 'Password must contain at least one symbol'
    }
  };

  const onSubmit = async (data) => {
    try {
      // First, send data to your backend
      console.log('Attempting to submit to backend...');
      const backendResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
        }),
      });
  
      console.log('Backend response status:', backendResponse.status);
      const backendData = await backendResponse.json();
      console.log('Backend response data:', backendData);
  
      if (!backendResponse.ok) {
        throw new Error(`Backend registration failed: ${backendResponse.status}`);
      }
  
      // Direct signup with Auth0
      console.log('Attempting Auth0 signup...');
      const auth0SignupUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/signup`;
      console.log('Auth0 signup URL:', auth0SignupUrl);
      
      const auth0Body = {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: data.email,
        password: data.password,
        connection: 'Username-Password-Authentication',
        user_metadata: {
          name: data.name,
          organization: data.organization,
          marketing_accepted: data.receiveMarketingCommunication.toString() // Convert boolean to string
        }
      };
      console.log('Auth0 request body:', auth0Body);
  
      const auth0Response = await fetch(auth0SignupUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(auth0Body)
      });
  
      console.log('Auth0 response status:', auth0Response.status);
      const auth0Data = await auth0Response.json();
      console.log('Auth0 response data:', auth0Data);
  
      if (!auth0Response.ok) {
        const errorData = auth0Data;
        throw new Error(`Auth0 signup failed: ${auth0Response.status} - ${JSON.stringify(errorData)}`);
      }
  
      // After successful signup, log the user in
      console.log('Signup successful, attempting login...');
      await loginWithRedirect({
        appState: {
          returnTo: '/dashboard'
        },
        authorizationParams: {
          login_hint: data.email
        }
      });
  
      setShowSuccessMessage(true);
  
    } catch (error) {
      console.error('Detailed error information:', {
        message: error.message,
        stack: error.stack,
        name: error.name
      });
      
      // If the error has response data, log it
      if (error.response) {
        try {
          const errorBody = await error.response.json();
          console.error('Error response body:', errorBody);
        } catch (e) {
          console.error('Could not parse error response body');
        }
      }
      
      setSignupError(`Error during signup: ${error.message}`);
    }
  };

  return (
    <div className="signon-page-container">
      <div className="signon-page-content">
        <div className="signon-text">
          <h2>Be the first to try Site Steer!</h2>
          <p>What you'll have when signing up:</p>
          <ul>
            <li>A concierge service from one of our experts to make adoption of Site Steer seamless.</li>
            <li>First month of use for free.</li>
            <li>You'll have disproportionate influence on the product and we'll make sure to build the product to fit your needs!</li>
          </ul>
        </div>
        <div className="signon-form-wrapper">
          <h1 style={headingStyle}>Signup for Early Bird Adoption</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: 'Name is required',
                    minLength: {
                      value: 2,
                      message: 'Name must be at least 2 characters'
                    }
                  }}
                  render={({ field }) => (
                    <TextInput 
                      {...field} 
                      label="Name" 
                      required 
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="organization"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Organization is required' }}
                  render={({ field }) => (
                    <TextInput 
                      {...field} 
                      label="Organization" 
                      required 
                      error={!!errors.organization}
                      helperText={errors.organization?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  }}
                  render={({ field }) => (
                    <TextInput 
                      {...field} 
                      label="Email" 
                      required 
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={passwordValidation}
                  render={({ field }) => (
                    <>
                      <TextInput 
                        {...field} 
                        type="password"
                        label="Password" 
                        required 
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                      {/* Password requirements indicator */}
                      <Box sx={{ mt: 1, fontSize: '0.875rem', color: 'text.secondary' }}>
                        <Typography variant="caption">Password must contain:</Typography>
                        <List dense sx={{ ml: 2 }}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                              {passwordRequirements.length ? 
                                <CheckCircle color="success" sx={{ fontSize: '16px' }} /> : 
                                <Cancel color="error" sx={{ fontSize: '16px' }} />}
                            </ListItemIcon>
                            <ListItemText 
                              primary="At least 8 characters"
                              primaryTypographyProps={{ fontSize: '0.875rem' }}
                            />
                          </ListItem>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                              {passwordRequirements.capital ? 
                                <CheckCircle color="success" sx={{ fontSize: '16px' }} /> : 
                                <Cancel color="error" sx={{ fontSize: '16px' }} />}
                            </ListItemIcon>
                            <ListItemText 
                              primary="One capital letter"
                              primaryTypographyProps={{ fontSize: '0.875rem' }}
                            />
                          </ListItem>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                              {passwordRequirements.number ? 
                                <CheckCircle color="success" sx={{ fontSize: '16px' }} /> : 
                                <Cancel color="error" sx={{ fontSize: '16px' }} />}
                            </ListItemIcon>
                            <ListItemText 
                              primary="One number"
                              primaryTypographyProps={{ fontSize: '0.875rem' }}
                            />
                          </ListItem>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                              {passwordRequirements.symbol ? 
                                <CheckCircle color="success" sx={{ fontSize: '16px' }} /> : 
                                <Cancel color="error" sx={{ fontSize: '16px' }} />}
                            </ListItemIcon>
                            <ListItemText 
                              primary="One symbol (e.g., !@#$%&*)"
                              primaryTypographyProps={{ fontSize: '0.875rem' }}
                            />
                          </ListItem>
                        </List>
                      </Box>
                    </>
                  )}
                />
              </Grid>
              {/* Privacy Notice Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Controller
                      name="privacyNoticeAccepted"
                      control={control}
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label={
                    <Typography>
                      I have read and acknowledge the <Link to="/privacy-notice" target="_blank" rel="noopener noreferrer">Privacy Notice</Link>.
                    </Typography>
                  }
                />
              </Grid>
              {/* Marketing Communications Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Controller
                      name="receiveMarketingCommunication"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label={<Typography>I accept to receive communication from Site Steer</Typography>}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  className="standard-button" 
                  disabled={!privacyNoticeAccepted}
                  fullWidth
                >
                  Signup
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      {/* Success Message Snackbar */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={handleCloseSuccessMessage}
        message="Registration successful! Redirecting to Auth0..."
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Error Message Snackbar */}
      <Snackbar
        open={!!signupError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={signupError}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseError}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default EarlySignon;