import React from 'react';
import FeatureSection from './FeaturesRoot';
import Footer from '../Homepage/Footer';
import CRM from '../images/CRM_site.png';
import Quotes from '../images/Quotes_site.png';
import Invoices from '../images/Invoices_site.png';
// Import your other images here

const FeaturesContainer = () => {
  const features = [
    {
      title: "Helps you keep organised",
      description: "SiteSteer allows you to manage all customers and contacts, all in one place.",
      image: CRM,
      imageAlt: "CRM System"
    },
    {
      title: "Fast, efficient quoting ",
      description: "“Quotes link seamlessly back to your customers to you can understand your potential work pipeline.”",
      image: Quotes, // Replace with your second image
      imageAlt: "Second Feature"
    },
    {
      title: "Save time with easy invoicing",
      description: "“Invoice can easily be generated by converting quotes to invoice.”",
      image: Invoices, // Replace with your third image
      imageAlt: "Third Feature"
    }
  ];

  return (
    <div className="features-container">
      {features.map((feature, index) => (
        <FeatureSection
          key={index}
          title={feature.title}
          description={feature.description}
          image={feature.image}
          imageAlt={feature.imageAlt}
        />
      ))}
      <Footer />
    </div>

  );
};

export default FeaturesContainer;