import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo_nobacgkrund_withcaptions.png';

const PricingSection = () => {
  const navigate = useNavigate();

  const registerInterest = () => {
    navigate('/early-signup');
  };

  return (
    <section className="pricing-section">
      <div className="pricing-content">
        <img src={logo} alt="SiteSteer Logo" className="homepage-logo" />
        <h2 className="pricing-title">Pricing</h2>
        <div className="pricing-divider"></div>
        
        <div className="pricing-card">
          <h3 className="pricing-card-title">FIRST MONTH FREE</h3>
          <button 
            onClick={() => registerInterest()}
            className="signup-button"
          >
            Sign up
          </button>
          <p className="pricing-description">
            SiteSteer is free for the first month, £5 per month after
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;