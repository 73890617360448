import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Box, Container, Typography, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const sections = ['Blog'];
  const homepage_links = ['https://blog.sitesteer.ai'];
  const currentYear = new Date().getFullYear();

  // function that checks whether a link is external or internal
  const handleNavigation = (link) => {
    if (link.startsWith('http')) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      navigate(link);
    }
    handleCloseNavMenu();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box component="footer" sx={{ bgcolor: 'black', py: 2 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="white" gutterBottom>
              Resources
            </Typography>
            <Box>
              {sections.map((section, index) => (
                <Link
                  key={section}
                  to={homepage_links[index]}
                  onClick={() => handleNavigation(homepage_links[index])}
                  style={{ color: 'white', textDecoration: 'none', display: 'block', marginBottom: '0.5rem' }}
                >
                  {section}
                </Link>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="white" gutterBottom>
              Legal
            </Typography>
            <Link to="/privacy-notice" style={{ color: 'white', textDecoration: 'none', display: 'block', marginBottom: '0.5rem' }}>
              Privacy Policy
            </Link>
            <Link to="/terms" style={{ color: 'white', textDecoration: 'none', display: 'block', marginBottom: '0.5rem' }}>
              Terms of Service
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="white" gutterBottom>
              Follow Us
            </Typography>
            <Box className="social-links">
              <Link to="https://www.facebook.com/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                <FacebookIcon className="social-media-icon"/>
              </Link>
              <Link to="https://www.instagram.com/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                <InstagramIcon className="social-media-icon"/>
              </Link>
              <Link to="https://www.linkedin.com/company/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                <LinkedInIcon className="social-media-icon"/>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box mt={0}>
          <Typography variant="body2" color="white" align="center">
            {"Copyright © "}
            {currentYear}
            {" SiteSteer AI. All rights reserved."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;