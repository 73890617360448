import React from 'react';
import logo from '../images/logo_nobacgkrund_withcaptions.png';
import { useNavigate, useLocation } from 'react-router-dom';

const HeroSection = () => {
  const navigate = useNavigate();
  
  const registerInterest = () => {
    navigate('/early-signup');
  };

  return (
    <section id="key-features" className="key-features-section">
      <h2 className="title-black-font"><span className="title-white-font">Construction ERP</span> you can feel good about</h2>
      <div className="hero-content">
        <img src={logo} className='homepage-logo'/>
        <button className='signup-button' onClick={() => registerInterest()}>Sign up</button>
      </div>
    </section>
  );
};

export default HeroSection;