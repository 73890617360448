// App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { PrivateRoute } from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import AdminManagement from "./pages/AdminManagement";
import Unauthorized from "./pages/Unauthorized";
import CreateForms from "./pages/CreateForms";
import Dashboard from "./pages/Dashboard";
import Templates from "./pages/Templates";
import EditSlate from './components/Template Components/EditSlate';
import YourForms from "./pages/YourForms";
import Team from "./pages/Team";
import CompanyDetails from "./pages/CompanyDetails";
import Blog from "./Homepage/Blog";
import Features from "./Homepage/Features";
import Pricing from "./pages/Pricing";
import PaymentDetails from "./pages/PaymentDetails";
import Prospects from "./pages/Prospects";
import Quotes from "./pages/Quotes";
import Invoices from "./pages/Invoices";
import CRM from "./pages/CRM";
import ProfilePage from "./pages/ProfilePage";
import EarlySignon from "./pages/EarlySignon";
import Projects from "./pages/Projects";
import ProjectDetails from './components/ProjectDetails';
import CreateProject from "./pages/CreateProject";
import PrivacyNotice from "./pages/PrivacyNotice";
import Root from './Root';

function App() {
  const { isAuthenticated, user } = useAuth0();
  console.log("isAuthenticated state:", isAuthenticated)
  console.log("user", user)

  return (
    <Router>
      <ResponsiveAppBar isAuthenticated={isAuthenticated} user={user} />
      <Routes>
        <Route path="/profile" element={<PrivateRoute component={ProfilePage} />} />
        <Route path="/projects" element={<PrivateRoute component={Projects} />} />
        <Route path="/create-project" element={<PrivateRoute component={CreateProject} />} />
        <Route path="/projects/:projectName" element={<PrivateRoute component={ProjectDetails} />} />
        <Route path="/create-slate" element={<PrivateRoute component={CreateForms} />} />
        <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
        <Route path="/slate-templates" element={<PrivateRoute component={Templates} />} />
        <Route path="/edit-slate/:templateId" element={<PrivateRoute component={EditSlate} />} />
        <Route path="/your-forms" element={<PrivateRoute component={YourForms} />} />
        <Route path="/manage-team" element={<PrivateRoute component={Team} />} />
        <Route path="/company-details" element={<PrivateRoute component={CompanyDetails} />} />
        <Route path="/payment-details" element={<PrivateRoute component={PaymentDetails} />} />
        <Route path="/pricing" element={<PrivateRoute component={Pricing} />} />
        <Route path="/crm" element={<PrivateRoute component={CRM} />} />
        <Route path="/prospects" element={<PrivateRoute component={Prospects} />} />
        <Route path="/quotes" element={<PrivateRoute component={Quotes} />} />
        <Route path="/invoices" element={<PrivateRoute component={Invoices} />} />
        <Route path="/admin-management" element={<AdminRoute component={AdminManagement} />} />
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        <Route exact path="/" element={<Root />} />
        <Route exact path="/early-signup" element={<EarlySignon />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/features" element={<Features />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
      </Routes>
    </Router>
  );
}

export default App;
