import React from 'react';
import { useNavigate } from 'react-router-dom';

const FeatureSection = ({ title, description, image, imageAlt }) => {
  const navigate = useNavigate();

  const registerInterest = () => {
    navigate('/early-signup');
  };
  
  return (
    <section className="features-root-section">
      <div className="features-root-content">
        {/* Text Column */}
        <div className="features-root-text-column">
          <p className="features-root-bubble">
            {title}
          </p>
          <p className="features-root-description">
            {description}
          </p>
        </div>

        {/* Image Column */}
        <div className="features-root-image-column">
          <img 
            src={image} 
            alt={imageAlt} 
            className="features-root-image" 
          />
        </div>
      </div>

      {/* Button container (outside columns) */}
      <div className="signup-button-container">
        <button 
          onClick={registerInterest}
          className="signup-button"
        >
          Sign up
        </button>
      </div>
    </section>
  );
};

export default FeatureSection;