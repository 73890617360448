// UsersFetch.jsx
import { useState, useEffect } from "react";

export const UsersFetch = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users/`);
      if (response.ok) {
        const data = await response.json();
        setUsers(data.users); // Assuming the API response has a `users` field
        setLoading(false);
      } else {
        setError('Failed to fetch user profiles');
        setLoading(false);
      }
    } catch (error) {
      setError('Error: ' + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, loading, error, refetchUsers: fetchUsers };
};