import React from 'react';
import constructionTeam from '../images/jobsite.png';  // Update path
import { HashLink as Link } from 'react-router-hash-link';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

// Contact Section
const ContactSection = () => {
  return (
    <section className="contact-section">
      <div className="contact-content">
        <div className="contact-image">
          <img src={constructionTeam} alt="Construction Team" />
        </div>
        <div className="contact-info">
          <h2>Contact Us Today</h2>
          <div className="contact-details">
            <div className="contact-group">
              <h3>Email</h3>
              <a href="mailto:info@sitesteer.ai">info@sitesteer.ai</a>
            </div>
            <div className="contact-group">
              <h3>Social</h3>
              <div className="social-links">
                <Link to="https://www.facebook.com/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                <FacebookIcon className="social-media-icon"/>
                </Link>
                <Link to="https://www.instagram.com/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                <InstagramIcon className="social-media-icon"/>
                </Link>
                <Link to="https://www.linkedin.com/company/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
                <LinkedInIcon className="social-media-icon"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;