import React from 'react';
import logo from '../images/logo_nobacgkrund_withcaptions.png';
import { useNavigate, useLocation } from 'react-router-dom';

const IntroBlog = () => {
  
  const registerInterest = () => {
    window.open('https://blog.sitesteer.ai', '_blank', 'noopener,noreferrer');
  };

  return (
    <section id="key-features" className="key-features-section">
      {/* <h2 className="title-black-font">
        You can find all our content on our dedicated community forum: {' '}
        <a href="https://blog.sitesteer.ai" target="_blank" rel="noopener noreferrer">
          blog.sitesteer.ai
        </a>
      </h2> */}
      <div className="blog-content">
        <button className='signup-button' onClick={registerInterest}>
          Explore Community forum
        </button>
        <img src={logo} alt="Site Logo" className='homepage-logo'/>
      </div>
    </section>
  );
};

export default IntroBlog;