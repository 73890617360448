import React from 'react';
import { useNavigate } from 'react-router-dom';
import cisImage from '../images/CisImage.png';
import constructionWorker from '../images/jobsite2.png';

const CISSection = () => {
  const navigate = useNavigate();

  const registerInterest = () => {
    navigate('/early-signup');
  };
  
  return (
    <section className="cis-section">
      <div className="cis-content">
        {/* Text Column */}
        <div className="cis-text-column">
          <p className="cis-description">
            SiteSteer allows you to you manage your quotes, CIS reverse charge and
            invoicing, all in one place.
          </p>
          <p className="cis-description">
            From your Audit Hub, get a clear understanding of payment cashflow
            into your business.
          </p>
        </div>

        {/* CIS Image and Button Column */}
        <div className="cis-middle-column">
          <img src={cisImage} alt="CIS Requirements" className="cis-gov-image" />
          <button 
            onClick={() => registerInterest()}
            className="signup-button"
          >
            Sign up
          </button>
        </div>

        {/* Construction Worker Column */}
        <div className="cis-worker-column">
          <img src={constructionWorker} alt="Construction Site" className="cis-worker-image" />
        </div>
      </div>
    </section>
  );
};

export default CISSection;